import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Form as BSForm } from 'react-bootstrap';
import { capitalize } from 'lodash';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {
  ReconVelocityWorkflowStep,
  ScheduleWeek,
  UpdateReconVelocityWorkflowStepData,
  WorkflowStepCategory,
  DAYS,
  AssignableUser,
  defaultScheduleWeek,
} from 'models';
import { Save } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';
import PermissionGate from 'components/PermissionGate';
import permissions from 'common/permissions';
import utils from 'utils';
import { APIResponse } from 'api';
import {
  useCreateReconVelocityWorkflowStep,
  useUpdateReconVelocityWorkflowStep,
  useReconVelocityWorkflowStep,
  useReconVelocityWorkflowSteps,
} from 'api/organizations/plugins/reconvelocity/workflows';
import NewParentWorkflowStepDialog from './NewParentWorkflowStepDialog';
import AssignableUsers from './AssignableUsers';
import TimeInput from './TimeInput';

import './WorkflowStepDetailView.scss';
import { useOnChange } from 'common/hooks';

interface WorkflowStepCategorySelectOption {
  label: string;
  value: WorkflowStepCategory;
}

interface WorkflowStepDetailViewProps {
  orgId: string;
  workflowStepDefinitionId: ReconVelocityWorkflowStep['id'];
  setSelectedStepId: (id: string | undefined) => void;
}

var WorkflowStepDetailView = (props: WorkflowStepDetailViewProps) => {
  const { orgId, workflowStepDefinitionId, setSelectedStepId } = props;
  const {
    data: { data: allSteps } = { data: [] },
    isLoading: workflowStepsIsLoading,
  } = useReconVelocityWorkflowSteps(orgId);
  let {
    data: workflowStepDefinition,
    isLoading: workflowStepDefinitionIsLoading,
  } = useReconVelocityWorkflowStep(orgId, workflowStepDefinitionId);
  if (workflowStepDefinitionId === 'BLANK_STEP') {
    const blankStep: ReconVelocityWorkflowStep = {
      activeVehicleCount: 0,
      assignableUsers: [],
      beginningStep: false,
      category: 'INCOMING',
      endingStep: false,
      id: '',
      name: '',
      shouldIncludeInReporting: false,
      enhancedStateTrackingEnabled: false,
      shouldRemoveSoldInEndingStep: false,
      stepOrder: 0,
    };
    workflowStepDefinition = { data: blankStep };
  }

  const updateStepMutation = useUpdateReconVelocityWorkflowStep(
    orgId,
    workflowStepDefinitionId
  );
  const createStepMutation = useCreateReconVelocityWorkflowStep(orgId);

  // State variables
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(workflowStepDefinition?.data.name ?? '');
  const [category, setCategory] = useState<WorkflowStepCategory>(
    workflowStepDefinition?.data.category ?? 'INCOMING'
  );
  const [parentStep, setParentStep] = useState(
    workflowStepDefinition?.data.parentStep
  );
  const [nextStep, setNextStep] = useState(
    workflowStepDefinition?.data.nextStep
  );
  const [endingStep, setEndingStep] = useState(
    workflowStepDefinition?.data.endingStep
  );
  const [shouldIncludeInReporting, setShouldIncludeInReporting] = useState(
    workflowStepDefinition?.data.shouldIncludeInReporting
  );
  const [shouldRemoveSoldInEndingStep, setShouldRemoveSoldInEndingStep] =
    useState(workflowStepDefinition?.data.shouldRemoveSoldInEndingStep);
  const [enhancedStateTrackingEnabled, setEnhancedStateTrackingEnabled] =
    useState(workflowStepDefinition?.data.enhancedStateTrackingEnabled);
  const [goalHoursAndMinutes, setGoalHoursAndMinutes] = useState(
    utils.time.getHoursAndMinutesFromSeconds(
      workflowStepDefinition?.data.goal?.goalTimeAsSeconds
    )
  );
  const [warningHoursAndMinutes, setWarningHoursAndMinutes] = useState(
    utils.time.getHoursAndMinutesFromSeconds(
      workflowStepDefinition?.data.goal?.warningTimeAsSeconds
    )
  );
  const [schedule, setSchedule] = useState<ScheduleWeek>(
    workflowStepDefinition?.data.schedule ?? defaultScheduleWeek
  );
  const [assignableUsers, setAssignableUsers] = useState(
    workflowStepDefinition?.data.assignableUsers
  );
  const [newParentStep, setNewParentStep] =
    useState<ReconVelocityWorkflowStep>();

  const onChangeWorflowStepDefinitionAndStatus = useCallback(() => {
    setName(workflowStepDefinition?.data.name ?? '');
    setCategory(workflowStepDefinition?.data.category ?? 'INCOMING');
    setParentStep(workflowStepDefinition?.data.parentStep);
    setNextStep(workflowStepDefinition?.data.nextStep);
    setEndingStep(workflowStepDefinition?.data.endingStep ?? false);
    setShouldIncludeInReporting(
      workflowStepDefinition?.data.shouldIncludeInReporting ?? false
    );
    setShouldRemoveSoldInEndingStep(
      workflowStepDefinition?.data.shouldRemoveSoldInEndingStep ?? false
    );
    setEnhancedStateTrackingEnabled(
      workflowStepDefinition?.data.enhancedStateTrackingEnabled ?? false
    );
    setGoalHoursAndMinutes(
      utils.time.getHoursAndMinutesFromSeconds(
        workflowStepDefinition?.data.goal?.goalTimeAsSeconds
      ) ?? { hours: 0, minutes: 0 }
    );
    setWarningHoursAndMinutes(
      utils.time.getHoursAndMinutesFromSeconds(
        workflowStepDefinition?.data.goal?.warningTimeAsSeconds
      ) ?? { hours: 0, minutes: 0 }
    );
    setSchedule(workflowStepDefinition?.data.schedule ?? defaultScheduleWeek);
    setAssignableUsers(workflowStepDefinition?.data.assignableUsers ?? []);
  }, [workflowStepDefinition]);

  useOnChange(
    onChangeWorflowStepDefinitionAndStatus,
    [
      workflowStepDefinition?.data,
      workflowStepDefinition?.data.id,
      updateStepMutation.status,
      createStepMutation.status,
    ],
    { callOnMount: true, strinfigy: true }
  );

  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();

  const openScheduleAlert = () =>
    openAlert(
      'Step schedule close time cannot be equal or before open time',
      'error'
    );

  const otherSteps = useMemo(
    () => allSteps?.filter((step) => step.id !== workflowStepDefinitionId),
    [allSteps, workflowStepDefinitionId]
  );

  const stepsWithoutChildren = useMemo(
    () =>
      allSteps?.filter((step) => {
        const childSteps = utils.workflow.getChildSteps(step.id, allSteps);
        return step.id !== workflowStepDefinitionId && !childSteps?.length;
      }),
    [allSteps, workflowStepDefinitionId]
  );

  const hourAndMinuteRegex = /([0-9][0-9]):([0-9][0-9])/;
  const validateScheduleTimes = (openTime?: string, closeTime?: string) => {
    if (!openTime || !closeTime) {
      return true;
    }
    const openMatches = openTime.match(hourAndMinuteRegex);
    const openHours = Number(openMatches?.[1]);
    const closeMatches = closeTime.match(hourAndMinuteRegex);
    const closeHours = Number(closeMatches?.[1]);
    if (openHours > closeHours) {
      return false;
    }

    if (openHours === closeHours) {
      const openMinutes = Number(openMatches?.[2]);
      const closeMinutes = Number(closeMatches?.[2]);
      if (openMinutes >= closeMinutes) {
        return false;
      }
    }

    return true;
  };

  const validateWeekScheduleTimes = (scheduleWeek: ScheduleWeek) =>
    DAYS.reduce((acc, day) => {
      const openTime = scheduleWeek[day]?.openTime;
      const closeTime = scheduleWeek[day]?.closeTime;
      return acc && validateScheduleTimes(openTime, closeTime);
    }, true);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!validateWeekScheduleTimes(schedule)) {
      openScheduleAlert();
      return;
    }

    // Build goal times
    const goalTimeAsSeconds =
      utils.time.getSecondsFromHoursAndMinutes(goalHoursAndMinutes);
    const warningTimeAsSeconds = utils.time.getSecondsFromHoursAndMinutes(
      warningHoursAndMinutes
    );

    const newStep: UpdateReconVelocityWorkflowStepData = {
      id:
        workflowStepDefinitionId === 'BLANK_STEP'
          ? undefined
          : workflowStepDefinitionId,
      name,
      category,
      parentStepId: newParentStep ? newParentStep.id : parentStep?.id,
      nextStepId: nextStep?.id,
      endingStep: !!endingStep,
      shouldIncludeInReporting: !!shouldIncludeInReporting,
      shouldRemoveSoldInEndingStep: !!shouldRemoveSoldInEndingStep,
      enhancedStateTrackingEnabled: !!enhancedStateTrackingEnabled,
      schedule,
      assignableUsers: assignableUsers ?? [],
      goal: {
        goalTimeAsSeconds,
        warningTimeAsSeconds,
      },
    };
    setIsSaving(true);

    let savedStep: APIResponse<ReconVelocityWorkflowStep>;
    try {
      if (newStep.id) {
        savedStep = await updateStepMutation.updateStepAsync(newStep);
      } else {
        savedStep = await createStepMutation.createStepAsync(newStep);
      }
      setSelectedStepId(savedStep.data.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        openAlert(error.message, 'error');
      } else {
        const message = `There was an API error when ${
          newStep.id ? 'updating' : 'creating'
        } the step`;
        openAlert(message, 'error');
      }
    }

    setIsSaving(false);
  };

  const categorySelectOptions: WorkflowStepCategorySelectOption[] = [
    {
      value: 'INCOMING',
      label: 'Incoming',
    },
    {
      value: 'TRADE_WALK_INSPECTION',
      label: 'Trade Walk/ Inspection',
    },
    {
      value: 'MECHANICAL',
      label: 'Mechanical',
    },
    {
      value: 'DETAIL',
      label: 'Detail',
    },
    {
      value: 'PICTURES_SYNDICATION',
      label: 'Pictures (Syndication)',
    },
    {
      value: 'MISCELLANEOUS',
      label: 'Miscellaneous',
    },
    {
      value: 'BODY_SHOP',
      label: 'Body Shop',
    },
    {
      value: 'INSPECTION',
      label: 'Inspection',
    },
    {
      value: 'PICTURES',
      label: 'Pictures',
    },
    {
      value: 'APPROVAL',
      label: 'Approval',
    },
    {
      value: 'SYNDICATION',
      label: 'Syndication',
    },
    {
      value: 'FINISHED',
      label: 'Finished',
    },
  ];

  if (workflowStepsIsLoading || workflowStepDefinitionIsLoading) {
    return <LoadingIndicator />;
  }

  if (!workflowStepDefinition) return null;

  return (
    <div className="WorkflowStepDetailView full-height flex-rows">
      <div className="full-width flex-columns flex-space-between padding">
        <div className="inline-block">
          <h3 className="inline">
            {workflowStepDefinition.data.name || 'New Step'}
          </h3>
          {workflowStepDefinition.data.id && (
            <div className="nowrap">
              ID:
              {workflowStepDefinition.data.id}
            </div>
          )}
        </div>
        <div className="inline-block">
          <PermissionGate
            permissions={[
              workflowStepDefinition.data.id
                ? permissions.ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_UPDATE
                : permissions.ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_CREATE,
            ]}
          >
            <Button
              startIcon={isSaving ? <LoadingIndicator size={15} /> : <Save />}
              variant="outlined"
              disabled={isSaving}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </PermissionGate>
        </div>
      </div>

      <div className="WorkflowStepDetailView__content flex-grow">
        <div className="WorkflowStepDetailView__input_container">
          <TextField
            className="WorkflowStepDetailView__input"
            autoFocus
            label="Name"
            variant="outlined"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            margin="dense"
          />
          <FormControl
            className="WorkflowStepDetailView__select"
            variant="outlined"
          >
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              label="Category"
              variant="outlined"
              required
              value={category}
              margin="dense"
              onChange={(e) => {
                setCategory(e.target.value as WorkflowStepCategory);
              }}
              displayEmpty
              disabled={isSaving}
            >
              {categorySelectOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  value={option.value}
                  id={`category-select-${option.value}`}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className="WorkflowStepDetailView__select"
            variant="outlined"
          >
            <InputLabel id="parent-step-select-label">Parent Step</InputLabel>
            <Select
              label="outlined"
              variant="outlined"
              required
              value={
                newParentStep?.id ? newParentStep.id : parentStep?.id ?? 'NONE'
              }
              margin="dense"
              onChange={(e) => {
                const selectedNewParentStep = allSteps.find(
                  (listStep) => listStep.id === e.target.value
                );
                if (
                  parentStep &&
                  utils.workflow.getChildSteps(parentStep.id).length &&
                  !newParentStep
                ) {
                  setNewParentStep(selectedNewParentStep);
                } else {
                  setParentStep(selectedNewParentStep);
                }
              }}
              displayEmpty
              disabled={isSaving}
            >
              <MenuItem value="NONE">None</MenuItem>
              {otherSteps?.map((stepDefinition) => (
                <MenuItem
                  key={stepDefinition.id}
                  value={stepDefinition.id}
                  id={`parent-step-select-${stepDefinition.id}`}
                >
                  {stepDefinition.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className="WorkflowStepDetailView__select"
            variant="outlined"
          >
            <InputLabel id="next-step-select-label">Next Step</InputLabel>
            <Select
              label="Next Step"
              variant="outlined"
              required
              value={
                (nextStep?.id !== workflowStepDefinition.data.id &&
                  nextStep?.id) ||
                'NONE'
              }
              margin="dense"
              onChange={(e) => {
                const newNextStep = allSteps.find(
                  (listStep) => listStep.id === e.target.value
                );
                setNextStep(newNextStep);
              }}
              displayEmpty
              disabled={isSaving}
            >
              <MenuItem value="NONE">None</MenuItem>
              {stepsWithoutChildren?.map((stepDefinition) => (
                <MenuItem
                  key={stepDefinition.id}
                  value={stepDefinition.id}
                  id={`parent-step-select-${stepDefinition.id}`}
                >
                  {stepDefinition.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="WorkflowStepDetailView__time">
          <label className="align-right">Goal Time:</label>
          <TimeInput
            placeholder="hours"
            value={goalHoursAndMinutes.hours || 0}
            maxLength={3}
            onChange={(value: number) => {
              setGoalHoursAndMinutes({
                ...goalHoursAndMinutes,
                hours: value,
              });
            }}
          />
          <p>Hours</p>
          <TimeInput
            placeholder="minutes"
            value={goalHoursAndMinutes.minutes || 0}
            onChange={(value: number) => {
              setGoalHoursAndMinutes({
                ...goalHoursAndMinutes,
                minutes: value,
              });
            }}
          />
          <p>Minutes</p>
        </div>

        <div className="WorkflowStepDetailView__time">
          <label className="align-right">Warning Time:</label>
          <TimeInput
            placeholder="minutes"
            value={warningHoursAndMinutes.hours || 0}
            maxLength={3}
            onChange={(value: number) => {
              setWarningHoursAndMinutes({
                ...warningHoursAndMinutes,
                hours: value,
              });
            }}
          />
          <p>Hours</p>
          <TimeInput
            placeholder="minutes"
            value={warningHoursAndMinutes.minutes || 0}
            onChange={(value: number) => {
              setWarningHoursAndMinutes({
                ...warningHoursAndMinutes,
                minutes: value,
              });
            }}
          />
          <p>Minutes</p>
        </div>

        {/* <Select name="scheduleMode" label="Schedule">
          <option value="SERVICE">Same as Rooftop Service Hours</option>
          <option value="CUSTOM">Custom Schedule</option>
        </Select> */}

        <BSForm.Group>
          <BSForm.Label />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={endingStep}
                  onChange={(_, checked) => {
                    setEndingStep(checked);
                  }}
                  color="primary"
                />
              }
              label="Ending Step"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldIncludeInReporting}
                  onChange={(_, checked) => {
                    setShouldIncludeInReporting(checked);
                  }}
                  color="primary"
                />
              }
              label="Include in Reporting"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldRemoveSoldInEndingStep}
                  onChange={(_, checked) => {
                    setShouldRemoveSoldInEndingStep(checked);
                  }}
                  color="primary"
                />
              }
              label="Complete Recon for Sold Vehicles"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={enhancedStateTrackingEnabled}
                  onChange={(_, checked) => {
                    setEnhancedStateTrackingEnabled(checked);
                  }}
                  color="primary"
                />
              }
              label="Enhanced State Tracking"
            />
            <br />
          </div>
        </BSForm.Group>

        <div className="WorkflowStepDetailView__schedule">
          <div className="WorkflowStepDetailView__schedule-grid">
            <strong>Schedule</strong>
            <label>Open:</label>
            <label>Close:</label>
            {DAYS.map((day) => {
              const dayName = capitalize(day);
              return (
                <Fragment key={day}>
                  <label className="align-right">{dayName}:</label>
                  <div>
                    <TextField
                      type="time"
                      className="WorkflowStepDetailView__time-input"
                      variant="outlined"
                      required={!!schedule?.[day]?.closeTime}
                      value={schedule?.[day]?.openTime ?? ''}
                      onChange={(e) => {
                        const isValid = validateScheduleTimes(
                          e.target.value,
                          schedule?.[day]?.closeTime
                        );
                        if (!isValid) {
                          openScheduleAlert();
                        }
                        if (!e.target.value && !schedule?.[day]?.closeTime) {
                          setSchedule({
                            ...schedule,
                            [day]: null,
                          });
                          return;
                        }
                        setSchedule({
                          ...schedule,
                          [day]: {
                            ...schedule?.[day],
                            openTime:
                              e.target.value && isValid ? e.target.value : null,
                            openInSecondsUTC: null,
                            closedInSecondsUTC: null,
                          },
                        });
                      }}
                      margin="dense"
                    />
                  </div>
                  <div>
                    <TextField
                      type="time"
                      className="WorkflowStepDetailView__time-input"
                      variant="outlined"
                      required={!!schedule?.[day]?.openTime}
                      value={schedule?.[day]?.closeTime ?? ''}
                      onChange={(e) => {
                        const isValid = validateScheduleTimes(
                          schedule?.[day]?.openTime,
                          e.target.value
                        );
                        if (!isValid) {
                          openScheduleAlert();
                        }
                        if (!e.target.value && !schedule?.[day]?.openTime) {
                          setSchedule({
                            ...schedule,
                            [day]: null,
                          });
                          return;
                        }
                        setSchedule({
                          ...schedule,
                          [day]: {
                            ...schedule?.[day],
                            closeTime:
                              e.target.value && isValid ? e.target.value : null,
                            openInSecondsUTC: null,
                            closedInSecondsUTC: null,
                          },
                        });
                      }}
                      margin="dense"
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
        <AssignableUsers
          orgId={orgId}
          assignableUsers={assignableUsers ?? []}
          currentStepName={name}
          onRemoveUserId={(userId: string) => {
            setAssignableUsers(
              (workflowStepDefinition!.data.assignableUsers =
                workflowStepDefinition?.data.assignableUsers?.filter(
                  (user) => user.id !== userId
                ))
            );
          }}
          onAddUserIds={(users: AssignableUser[]) => {
            setAssignableUsers(users);
          }}
        />
      </div>
      {newParentStep && (
        <NewParentWorkflowStepDialog
          orgId={orgId}
          workflowStepDefinition={newParentStep}
          allSteps={allSteps}
          onComplete={async (event) => {
            await handleSubmit(event);
          }}
          onClose={() => {
            setNewParentStep(undefined);
          }}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={() => {
          closeAlert();
          updateStepMutation.reset();
          createStepMutation.reset();
        }}
        duration={3500}
      />
    </div>
  );
};

export default WorkflowStepDetailView;
