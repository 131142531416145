import React, { useCallback, useEffect } from 'react';
import {
  Dialog as MaterialDialog,
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import '../PluginDialog.scss';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVelocityEngagePlugin,
  updateVelocityEngagePlugin,
} from 'store/actions/organizations/plugins/velocityengage';
import { RootState } from 'store/reducers';

import { assignPlugin } from 'store/actions/organizations/plugins';
import { Organization, PluginItem, VelocityEngagePluginItem } from 'models';
import { getPluginOptions } from 'store/actions/organizations/plugins/options';
import strings from 'common/strings';
import Content from './Admin/Content';
import StyleTab from './StyleTab/StyleTab';
import MarketingTagsTab from './MarketingTagsTab/MarketingTagsTab';
import SharedInventoryTab from './SharedInventoryTab/SharedInventoryTab';
import FolioTemplateContainer from './Admin/FolioTemplateContainer';

import './VelocityEngagePluginDialog.scss';
import { useOnChange } from 'common/hooks';
import OrganizationIdContext from './OrganizationIdContext';

export interface VelocityEngagePluginDialogProps {
  orgId: Organization['id'];
  plugin: PluginItem;
  onSave?: () => void;
  onClose?: () => void;
}

export var VisibilityToggle = ({
  active,
  children,
}: React.PropsWithChildren<{ active: boolean }>) => {
  const hideStyle: React.CSSProperties = {
    visibility: 'hidden',
    display: 'none',
    height: 0,
    opacity: 0,
    margin: 0,
    padding: 0,
  };
  return <div style={!active ? hideStyle : {}}>{children}</div>;
};

const orgDialogTabs: TabHeaderItem[] = [
  { key: 'content', label: 'Content' },
  { key: 'style', label: 'Style' },
  { key: 'folioTemplate', label: 'Folio Template' },
  { key: 'marketingTags', label: 'Marketing Tags' },
  { key: 'sharedInventory', label: 'Shared Inventory' },
];

const VelocityEngagePluginDialog = ({
  orgId,
  plugin,
  onSave,
  onClose,
}: VelocityEngagePluginDialogProps) => {
  const dispatch = useDispatch();
  const { general: generalSettings } = useSelector(
    ({
      organizations: {
        plugins: { velocityengage },
      },
    }: RootState) => ({ ...velocityengage })
  );
  const navigate = useNavigate();

  const [saveChangesDialogOpen, setSaveChangesDialogOpen] =
    React.useState(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState(
    generalSettings.data
  );
  const [isTogglingEnabled, setIsTogglingEnabled] = React.useState(false);

  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();

  const defaultTabKey = 'content';
  const baseRoute = 'velocityengage/';

  const onMount = useCallback(() => {
    navigate(baseRoute + defaultTabKey, { replace: true });
  }, [navigate]);

  useOnChange(onMount, null, { callOnMount: true });

  async function createPlugin() {
    await dispatch(
      assignPlugin({
        orgId,
        pluginId: 'velocityvdp',
        data: { enabled: true },
      })
    );
    await dispatch(getVelocityEngagePlugin({ orgId }));
  }

  useOnChange(
    () => {
      if (plugin.id) {
        dispatch(getVelocityEngagePlugin({ orgId }));
      } else {
        createPlugin();
      }
    },
    [dispatch, orgId, plugin],
    {
      callOnMount: true,
      layoutEffect: true,
      strinfigy: true,
    }
  );

  useEffect(() => {
    if (
      !generalSettings.error ||
      generalSettings.error.message === 'Not Found'
    ) {
      return;
    }

    openAlert(generalSettings.error.message, 'error');
  }, [generalSettings.error, openAlert]);

  const toggleEnabledSwitch = useCallback(
    async (enabled: boolean) => {
      try {
        setIsTogglingEnabled(true);

        if (generalSettings.data?.id && plugin.id) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: { enabled },
            })
          );
        } else {
          await dispatch(
            assignPlugin({
              orgId,
              pluginId: 'velocityvdp',
              data: { enabled },
            })
          );

          await dispatch(getPluginOptions({ orgId }));
          await dispatch(getVelocityEngagePlugin({ orgId }));
        }

        setIsTogglingEnabled(false);
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId, plugin]
  );

  const toggleEnabledBadgeSwitch = useCallback(
    async (dealerdotcomEnabled: boolean) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                dealerdotcomEnabled,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const toggleOpenEmbeddedSwitch = useCallback(
    async (badgeOpenEmbedded: boolean) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                badgeOpenEmbedded,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const saveBadgeAccountId = useCallback(
    async (dealerdotcomAccountId: string) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                dealerdotcomAccountId,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const saveEngageSettings = useCallback(
    async (data: VelocityEngagePluginItem) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data,
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const saveDealerdotcomLocation = useCallback(
    async (dealerdotcomLocation: string) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                dealerdotcomLocation,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const toggleDealerSiteShowModules = useCallback(
    async (dealerSiteShowModules: boolean) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                dealerSiteShowModules,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const toggleWindowStickerModule = useCallback(
    async (windowStickerModule: boolean) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                windowStickerModule,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );

  const toggleDisplayWindowStickerPriceField = useCallback(
    async (displayWindowStickerPriceField: boolean) => {
      try {
        if (generalSettings.data) {
          await dispatch(
            updateVelocityEngagePlugin({
              orgId,
              data: {
                ...generalSettings.data,
                displayWindowStickerPriceField,
              },
            })
          );
        }
      } finally {
        onSave?.();
      }
    },
    [dispatch, generalSettings.data, onSave, orgId]
  );
  const handleSavedChanges = (
    event: React.ChangeEvent<{}>,
    saveChanges?: boolean
  ) => {
    if (unsavedChanges && saveChanges) {
      saveEngageSettings(unsavedChanges);
    }
    setSaveChangesDialogOpen(false);
  };

  return (
    <OrganizationIdContext.Provider value={orgId}>
      <MaterialDialog open fullWidth maxWidth="xl">
        <div className="engage-plugin-dialog-container padding-lg">
          <div className="flex-row flex-space-between valign-center flex-grow padding-bottom-md">
            <Typography variant="h5">
              Configure Velocity Engage Plugin
            </Typography>
            <div className="flex-row valign-center" id="engage-plugin-toggle">
              <FormControlLabel
                className="margin-right-md"
                label={
                  generalSettings.loading || isTogglingEnabled ? (
                    <LoadingIndicator size={26} />
                  ) : (
                    <FormLabel>
                      {generalSettings.data?.enabled ? 'Enabled' : 'Disabled'}
                    </FormLabel>
                  )
                }
                labelPlacement="start"
                control={
                  <Switch
                    inputProps={{
                      'aria-label': 'Velocity Engage Plugin Enable checkbox',
                    }}
                    checked={generalSettings.data?.enabled ?? false}
                    disabled={generalSettings.loading || isTogglingEnabled}
                    onChange={(_, checked) => toggleEnabledSwitch(checked)}
                  />
                }
              />

              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Tabs
            tabHeaderItems={orgDialogTabs}
            defaultTabKey={defaultTabKey}
            basename={baseRoute}
          />
          <Divider />
          <Routes basename="velocityengage/">
            <Route
              path="content"
              element={
                <Content
                  engagePluginSettings={generalSettings}
                  saveEngageSettings={saveEngageSettings}
                  setIsUnsavedChanges={(x) => {}}
                  setUnsavedChanges={setUnsavedChanges}
                />
              }
            />
            <Route
              path="style"
              element={
                <StyleTab
                  engagePluginSettings={generalSettings}
                  saveEngageSettings={saveEngageSettings}
                  setIsUnsavedChanges={(x) => {}}
                  setUnsavedChanges={setUnsavedChanges}
                />
              }
            />
            <Route
              path="folioTemplate"
              element={<FolioTemplateContainer orgId={orgId} />}
            />
            <Route
              path="marketingTags"
              element={
                <div
                  className="padding-xl contentArea"
                  id="marketing-tags-container"
                >
                  <MarketingTagsTab
                    orgId={orgId}
                    saveBadgeAccountId={saveBadgeAccountId}
                    saveDealerdotcomLocation={saveDealerdotcomLocation}
                    toggleEnabledBadgeSwitch={toggleEnabledBadgeSwitch}
                    toggleOpenEmbeddedSwitch={toggleOpenEmbeddedSwitch}
                    engagePluginSettings={generalSettings}
                    toggleDealerSiteShowModules={toggleDealerSiteShowModules}
                    toggleWindowStickerModule={toggleWindowStickerModule}
                    toggleDisplayWindowStickerPriceField={
                      toggleDisplayWindowStickerPriceField
                    }
                  />
                </div>
              }
            />
            <Route
              path="sharedInventory"
              element={
                <div className="padding-xl contentArea">
                  <SharedInventoryTab
                    saveEngageSettings={saveEngageSettings}
                    engagePluginSettings={generalSettings}
                  />
                </div>
              }
            />
          </Routes>
        </div>
        <MaterialDialog open={saveChangesDialogOpen} fullWidth maxWidth="xs">
          <div className="save-changes-dialog-header-container">
            <Typography
              className="save-changes-dialog-header-text"
              variant="h5"
            >
              {strings.UNSAVED_CHANGES}
            </Typography>
          </div>
          <Divider />
          <div className="padding-lg">
            <div>{strings.UNSAVED_CHANGES_DIALOG}</div>
            <div className="yes-no-button-dialog-container">
              <button
                type="button"
                className="no-button"
                onClick={handleSavedChanges}
              >
                {strings.NO}
              </button>
              <button
                type="button"
                className="button"
                onClick={(e) => handleSavedChanges(e, true)}
              >
                {strings.YES}
              </button>
            </div>
          </div>
        </MaterialDialog>
      </MaterialDialog>

      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        duration={3500}
        onClose={closeAlert}
      />
    </OrganizationIdContext.Provider>
  );
};

export default VelocityEngagePluginDialog;
