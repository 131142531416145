import { configuredFetch } from 'api/base';
import { VelocityEngagePluginItem } from 'models/plugins/velocityEngagePlugin';
import { Organization } from 'models';
import media from './media';
import folioTemplate from './folioTemplate';
import inventory from './inventory';

export type VelocityEngagePluginRequestParams = {
  orgId: Organization['id'];
};

function baseUrl({ orgId }: VelocityEngagePluginRequestParams) {
  return `/orgs/${orgId}/plugins/velocityvdp`;
}

/**
 * Get Velocity Engage Plugin
 * Endpoint: GET /orgs/{orgId}/plugins/velocityvdp
 */
async function getPlugin({ orgId }: VelocityEngagePluginRequestParams) {
  const url = baseUrl({ orgId });
  const { data } = await configuredFetch<VelocityEngagePluginItem>(url);
  return data;
}

/**
 * Update Velocity Engage Plugin
 * Endpoint: PUT /orgs/{orgId}/plugins/velocityvdp
 */
export type UpdateVelocityEngagePluginRequestParams =
  VelocityEngagePluginRequestParams & {
    data: Partial<VelocityEngagePluginItem>;
  };
async function updatePlugin({
  orgId,
  data: updateData,
}: UpdateVelocityEngagePluginRequestParams) {
  const url = baseUrl({ orgId });
  const { data } = await configuredFetch<VelocityEngagePluginItem>(url, {
    method: 'put',
    body: JSON.stringify(updateData),
  });
  return data;
}

export default {
  baseUrl,
  getPlugin,
  updatePlugin,
  folioTemplate,
  inventory,
  media,
};
