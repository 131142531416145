import React, { useState } from 'react';
import {
  Close,
  Delete,
  Edit,
  Star,
  StarBorder,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {
  Box,
  Button,
  createStyles,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  TextField,
} from '@material-ui/core';
import { FolioDocumentTemplate } from 'models';
import strings from 'common/strings';
import './EngageMediaItem.scss';
import { isMobileViewport } from 'common/viewport';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import EngageMediaPlaceholder from '../EngageMediaPlaceholder';
import api from '../../../../../../../api';

const useStyles = makeStyles(() =>
  createStyles({
    editFields: {
      width: '100%',
    },
    input: {
      display: 'none',
    },
    engageMediaItemEditModal: {
      width: '560px',
    },
  })
);

interface EngageMediaItemProps {
  orgId: string;
  folioId: string;
  media: FolioDocumentTemplate;
  inFeaturedMediaContainer: boolean;
  onStar: (document: FolioDocumentTemplate) => void;
  onPublish: (document: FolioDocumentTemplate) => void;
  onDelete: (document: FolioDocumentTemplate) => void;
  onEdit: (
    document: FolioDocumentTemplate,
    title: string,
    previewImageUrl: string,
    mediaUrl?: string
  ) => void;
  isDragging: boolean;
}

interface EngageMediaItemComponentProps {
  inFeaturedMediaContainer: boolean;
  onStar: (document: FolioDocumentTemplate) => void;
  onPublish: (document: FolioDocumentTemplate) => void;
  media: FolioDocumentTemplate;
  onMediaClick: () => void;
  onEditClick: () => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  isDragging: boolean;
}

const EngageMediaItemOverlay: React.FC<EngageMediaItemComponentProps> = ({
  inFeaturedMediaContainer = false,
  onStar,
  onPublish,
  media,
  onMediaClick,
  onEditClick,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isDragging,
}: EngageMediaItemComponentProps) => {
  const { featured: isFeatured, visible: isVisible, displayLabel } = media;
  const StarIcon = isFeatured ? Star : StarBorder;
  const starIconClassName = isFeatured ? 'EngageMediaItem-star-icon' : '';

  const EyeIcon = isVisible ? Visibility : VisibilityOff;
  const eyeIconClassName = isVisible ? '' : 'EngageMediaItem-eye-icon';

  return (
    <div className="EngageMediaItem item">
      <div className="item-content">
        <div>
          <div
            className="EngageMediaItem-info"
            role="none"
            onClick={() => {
              if (!isDragging) {
                onMediaClick();
              }
            }}
          >
            {media.url || !media.canUpdateUrl ? (
              <>
                <img
                  className="EngageMediaItem-info-image"
                  src={media.previewUrl}
                  alt="car"
                />
                <div className="EngageMediaItem-info-image-overlay" />
                <span className="EngageMediaItem-info-title">
                  {displayLabel}
                </span>
              </>
            ) : (
              <EngageMediaPlaceholder
                title={media.displayLabel}
                onClick={onEditClick}
              />
            )}
          </div>
          <div className="EngageMediaItem-icons">
            <StarIcon
              className={`EngageMediaItem-icon ${starIconClassName}`}
              onClick={() => onStar(media)}
            />
            {!inFeaturedMediaContainer && (
              <>
                <EyeIcon
                  className={`EngageMediaItem-icon ${eyeIconClassName}`}
                  onClick={() => onPublish(media)}
                />
                <Edit className="EngageMediaItem-icon" onClick={onEditClick} />
                {media.canDelete && (
                  <>
                    <Delete
                      className="EngageMediaItem-icon"
                      onClick={() => {
                        if (isDeleteModalOpen) {
                          setIsDeleteModalOpen(false);
                        }
                        setIsDeleteModalOpen(!isDeleteModalOpen);
                      }}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const EngageMediaItemHorizontal: React.FC<EngageMediaItemComponentProps> = ({
  inFeaturedMediaContainer = false,
  onStar,
  onPublish,
  media,
  onMediaClick,
  onEditClick,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isDragging,
}: EngageMediaItemComponentProps) => {
  const { featured, visible, displayLabel } = media;
  const StarIcon = featured ? Star : StarBorder;
  const starIconClassName = featured
    ? 'EngageMediaItemHorizontal-star-icon'
    : '';

  const EyeIcon = visible ? Visibility : VisibilityOff;
  const eyeIconClassName = visible ? '' : 'EngageMediaItemHorizontal-eye-icon';

  return (
    <div className="item EngageMediaItemHorizontal-margin">
      <div className="item-content">
        <div className="EngageMediaItemHorizontal">
          <div
            className="EngageMediaItemHorizontal-image-container"
            role="none"
            onClick={() => {
              if (!isDragging) {
                onMediaClick();
              }
            }}
          >
            {media.url ? (
              <img
                className="EngageMediaItemHorizontal-image"
                src={media.previewUrl}
                alt="car"
              />
            ) : (
              <EngageMediaPlaceholder
                title={media.displayLabel}
                onClick={onEditClick}
              />
            )}
          </div>
          <div
            className={`EngageMediaItemHorizontal-info${
              media.url ? '' : ' EngageMediaItemHorizontal-info-padding'
            }`}
          >
            <span className="EngageMediaItemHorizontal-title">
              {displayLabel}
            </span>
            <div className="EngageMediaItemHorizontal-icons">
              <StarIcon
                className={`EngageMediaItemHorizontal-icon ${starIconClassName}`}
                onClick={() => onStar(media)}
              />
              {!inFeaturedMediaContainer && (
                <>
                  <EyeIcon
                    className={`EngageMediaItemHorizontal-icon ${eyeIconClassName}`}
                    onClick={() => onPublish(media)}
                  />
                  {media.canDelete && (
                    <>
                      <Delete
                        className="EngageMediaItemHorizontal-icon"
                        onClick={() => {
                          if (isDeleteModalOpen) {
                            setIsDeleteModalOpen(false);
                          }
                          setIsDeleteModalOpen(!isDeleteModalOpen);
                        }}
                      />
                      <Edit
                        className="EngageMediaItemHorizontal-icon"
                        onClick={onEditClick}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EngageMediaItem: React.FC<EngageMediaItemProps> = ({
  folioId,
  orgId,
  media,
  inFeaturedMediaContainer,
  onStar,
  onEdit,
  onPublish,
  onDelete,
  isDragging,
}: EngageMediaItemProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddNewMedia] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [mediaTitle, setMediaTitle] = useState(media.displayLabel || '');
  const [isMediaFileUpload, setIsMediaFileUpload] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(media.url || '');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isMediaPreviewFileUpload, setIsMediaPreviewFileUpload] =
    useState(false);
  const [mediaPreviewUrl, setMediaPreviewUrl] = useState(media.previewUrl);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();

  const onMediaClick = () => {
    if (media.url) {
      window.open(media.url, '_blank');
    } else {
      setErrorMessage(strings.NO_DOCUMENT_URL);
    }
  };

  const onEditClick = () => {
    if (isEditModalOpen) {
      setIsEditModalOpen(false);
    }
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleChangeEditMediaType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setIsMediaFileUpload(event.target.value === 1);
  };

  const handleChangeEditMediaPreviewImageType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setIsMediaPreviewFileUpload(event.target.value === 1);
  };

  const handleSelectFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setSelectedFile(file);
      const fileData = new FormData();
      fileData.append('file', file);
      const fileUrl =
        await api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
          {
            orgId,
            folioTemplateId: folioId,
            file: fileData,
          }
        );
      setMediaUrl(fileUrl);
    }
  };

  const handleSelectPreviewImageFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setSelectedFile(file);
      const imageData = new FormData();
      imageData.append('file', file);
      const imageUrl =
        await api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
          {
            orgId,
            folioTemplateId: folioId,
            file: imageData,
          }
        );
      setMediaPreviewUrl(imageUrl);
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
    setMediaTitle(media.displayLabel || '');
    setIsMediaFileUpload(false);
    setMediaUrl(media.url || '');
    setSelectedFile(undefined);
    setIsMediaPreviewFileUpload(false);
    setMediaPreviewUrl(media.previewUrl || '');
  };

  return (
    <>
      {isMobileViewport() ? (
        <EngageMediaItemHorizontal
          inFeaturedMediaContainer={inFeaturedMediaContainer}
          onStar={onStar}
          onPublish={onPublish}
          media={media}
          onMediaClick={onMediaClick}
          onEditClick={onEditClick}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDragging={isDragging}
        />
      ) : (
        <EngageMediaItemOverlay
          inFeaturedMediaContainer={inFeaturedMediaContainer}
          onStar={onStar}
          onPublish={onPublish}
          media={media}
          onMediaClick={onMediaClick}
          onEditClick={onEditClick}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDragging={isDragging}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={<span>{errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage('')}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <MaterialDialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        maxWidth="md"
        aria-labelledby="edit-media-title-dialog-title"
      >
        <MaterialDialogTitle
          id="edit-media-title-dialog-title"
          className={classes.engageMediaItemEditModal}
        >
          {isAddNewMedia ? strings.ADD_MEDIA : strings.EDIT_MEDIA}
        </MaterialDialogTitle>
        <MaterialDialogContent className={classes.engageMediaItemEditModal}>
          {media.canUpdateUrl && (
            <div>
              <InputLabel shrink htmlFor="edit-media-doc-type-select">
                Document Type
              </InputLabel>
              <Select
                label="Document Type"
                id="edit-media-doc-type-select"
                value={isMediaFileUpload ? 1 : 0}
                onChange={handleChangeEditMediaType}
                name="edit-media-type-input"
                variant="outlined"
                className={classes.editFields}
              >
                <MenuItem value={0}>Link</MenuItem>
                <MenuItem value={1}>File Upload</MenuItem>
              </Select>
            </div>
          )}
          <div>
            <TextField
              label="Title"
              value={mediaTitle}
              onChange={(event) => setMediaTitle(event.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.editFields}
            />
          </div>
          {media.canUpdateUrl &&
            (isMediaFileUpload ? (
              <Box my={1}>
                <input
                  type="file"
                  id={`${media.id}file-input`}
                  multiple
                  onChange={handleSelectFile}
                  className={classes.input}
                />
                <label htmlFor={`${media.id}file-input`}>
                  <Button variant="outlined" component="span" disableElevation>
                    Select File
                  </Button>
                </label>
                <Box component="span" ml={2}>
                  {selectedFile?.name
                    ? selectedFile?.name
                    : 'Select a file to upload.'}
                </Box>
              </Box>
            ) : (
              <div>
                <TextField
                  label="URL - Use {vin} to pass the VIN to the URL, e.g., https://kbb.com/kbbreport/{vin}"
                  value={mediaUrl}
                  onChange={(event) => setMediaUrl(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.editFields}
                />
              </div>
            ))}
          <div>
            <InputLabel shrink htmlFor="edit-media-preview-image-type-select">
              Thumbnail Type
            </InputLabel>
            <Select
              label="Thumbnail Type"
              id="edit-media-preview-image-type-select"
              value={isMediaPreviewFileUpload ? 1 : 0}
              onChange={handleChangeEditMediaPreviewImageType}
              name="edit-media-preview-image-type-input"
              variant="outlined"
              className={classes.editFields}
            >
              <MenuItem value={0}>Link</MenuItem>
              <MenuItem value={1}>File Upload</MenuItem>
            </Select>
          </div>
          {isMediaPreviewFileUpload ? (
            <Box my={2}>
              <input
                type="file"
                id={`${media.id}preview-image-file-input`}
                multiple
                onChange={handleSelectPreviewImageFile}
                className={classes.input}
              />
              <label htmlFor={`${media.id}preview-image-file-input`}>
                <Button variant="outlined" component="span" disableElevation>
                  SELECT THUMBNAIL IMAGE
                </Button>
              </label>
            </Box>
          ) : (
            <div>
              <TextField
                label="Thumbnail Image URL"
                value={mediaPreviewUrl}
                onChange={(event) => setMediaPreviewUrl(event.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.editFields}
              />
            </div>
          )}
          <div>
            <img src={mediaPreviewUrl} alt="Thumbnail" />
          </div>
        </MaterialDialogContent>
        <MaterialDialogActions className={classes.engageMediaItemEditModal}>
          <Button onClick={handleCancelEdit} color="primary">
            {strings.CANCEL}
          </Button>
          <Button
            onClick={async () => {
              if (onEdit) {
                setIsUpdating(true);
                await onEdit(media, mediaTitle, mediaPreviewUrl, mediaUrl);
                setIsUpdating(false);
                setIsEditModalOpen(false);
              }
            }}
            color="primary"
          >
            {isUpdating ? <LoadingIndicator /> : strings.SAVE}
          </Button>
        </MaterialDialogActions>
      </MaterialDialog>
      <MaterialDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        maxWidth="xs"
        aria-labelledby="edit-media-delete-dialog-title"
      >
        <MaterialDialogTitle id="edit-media-delete-dialog-title">
          {strings.ARE_YOU_SURE}
        </MaterialDialogTitle>
        <MaterialDialogContent>
          <div>
            Are you sure you want to delete media from this folio template?
          </div>
        </MaterialDialogContent>
        <MaterialDialogActions>
          <Button onClick={() => setIsDeleteModalOpen(false)} color="primary">
            {strings.CANCEL}
          </Button>
          <Button
            onClick={async () => {
              if (onDelete) {
                setIsDeleting(true);
                onDelete(media);
                setIsDeleting(false);
                setIsDeleteModalOpen(false);
              }
            }}
            color="primary"
          >
            {isDeleting ? strings.DELETE : <LoadingIndicator />}
          </Button>
        </MaterialDialogActions>
      </MaterialDialog>
    </>
  );
};

export default EngageMediaItem;
