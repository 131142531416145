import { AuthenticationResponse, User } from 'models';
import { configuredFetch } from '../base';

export type AuthenticateRequestParams = {
  username: User['id'];
  password: string;
};
async function authenticate({
  username,
  password,
}: AuthenticateRequestParams): Promise<AuthenticationResponse> {
  const { data } = await configuredFetch<AuthenticationResponse>(
    '/authenticate',
    {
      method: 'post',
      body: JSON.stringify({ username, password }),
    }
  );

  return data;
}

async function refreshToken(): Promise<AuthenticationResponse> {
  const { data } = await configuredFetch<AuthenticationResponse>(
    '/authenticate'
  );
  return data;
}

async function logout(): Promise<void> {
  const { data } = await configuredFetch('/authenticate/logout');
  return data;
}

export default {
  authenticate,
  refreshToken,
  logout,
};
