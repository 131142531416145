const splashScreen = document.getElementById('RV-splash-screen')!;
const splashScreenText = document.getElementById(
  'RV-splash-screen-loading-text'
)!;

export default {
  show() {
    splashScreenText.innerHTML = '&nbsp;LOADING&hellip;';
    splashScreen.className = 'RV-splash-screen-app-restart';
  },

  hide() {
    splashScreenText.innerHTML = '';
    splashScreen.className = 'RV-splash-screen-app-started';
  },
};
