import React, {
  useState,
  useEffect,
  ReactElement,
  SetStateAction,
} from 'react';
import { Delete, Add } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import { OrganizationItemLink, User } from 'models';
import { useRemoveUserFromOrg } from 'api/organizations/users';
import UserOrganizationsDialog from './UserOrganizationsDialog';
import './UserOrganizationListView.scss';

interface UserOrganizationsListViewProps {
  userId: string;
  selectedOrganizationId?: string;
  setSelectedOrganizationId: (selectedOrganizationId: string) => void;
  userOrganizations?: OrganizationItemLink[];
  setUser: (user: SetStateAction<User>) => void;
}

const UserOrganizationsListView = (props: UserOrganizationsListViewProps) => {
  const {
    selectedOrganizationId,
    setSelectedOrganizationId,
    userOrganizations = [],
    userId,
    setUser,
  } = props;
  const [checkedOrganizations, setCheckedOrganizations] = React.useState<{
    [key: string]: OrganizationItemLink;
  }>({});
  const {
    confirmationMessage,
    onAccept,
    onDeny,
    isConfirmationLoading,
    isConfirmationDialogOpen,
    primaryButtonLabel,
    openConfirmationDialog,
  } = useConfirmationDialog();

  const [isUserOrganizationsDialogOpen, setUserOrganizationsDialogOpen] =
    useState(false);
  const openUserOrganizationsDialog = () =>
    setUserOrganizationsDialogOpen(true);
  const closeUserOrganizationsDialog = () =>
    setUserOrganizationsDialogOpen(false);

  const removeUserMutation = useRemoveUserFromOrg();

  const handleAdd = openUserOrganizationsDialog;

  useEffect(() => {
    if (userOrganizations && userOrganizations?.length > 0) {
      const userOrganizationIds = userOrganizations?.reduce(
        (acc, o) =>
          o?.id
            ? {
                ...acc,
                [o.id]: o,
              }
            : acc,
        {}
      );
      setCheckedOrganizations(userOrganizationIds);
    }
  }, [setCheckedOrganizations, userOrganizations]);

  // const validateDefaultOrganization = () => {
  //   const FIRST_OR_LAST_USER_ORGANIZATION = 1;
  //   if (
  //     editUserDialogState.organizations.length ===
  //     FIRST_OR_LAST_USER_ORGANIZATION
  //   ) {
  //     const organization = organizations[0];
  //     editUserDialogState.defaultOrganization = organization;
  //   }
  // };

  // const handleOrganizationsAdded = (
  //   userOrganizations: Organization[],
  //   lastOrganizationAdded: Organization,
  // ) => {
  //   const index = userOrganizations.findIndex(
  //     (item) => item.id === lastOrganizationAdded.id,
  //   );
  //   const newSelectionState = new SelectionState<Organization>();
  //   newSelectionState.index = index;
  //   newSelectionState.item = lastOrganizationAdded;
  //   updateSelectionState(newSelectionState);

  //   validateDefaultOrganization();
  // };

  const handleDelete = async (organization: any) => {
    if (!organization) return;

    const onAcceptRemoveOrganization = async () => {
      await removeUserMutation.removeUserFromOrgAsync({
        orgId: organization.id,
        userId,
      });
      setSelectedOrganizationId(userOrganizations[0].id);
    };
    openConfirmationDialog({
      messageOverride: `Are you sure you want to remove organization ${organization.name} from this user?`,
      onAcceptOverride: async () => onAcceptRemoveOrganization(),
      primaryButtonLabelOverride: 'Remove',
    });
  };

  const handleClick = (id: string) => {
    setSelectedOrganizationId(id);
  };

  const INDENT_PX = 20;

  const displayOrganizations = (
    level: number,
    orgs?: OrganizationItemLink[]
  ): ReactElement => (
    <>
      {orgs?.map((organization: OrganizationItemLink) => (
        <React.Fragment key={organization.id}>
          <div
            className={`ReactVirtualized__Table__row Table__row ${
              organization.id === selectedOrganizationId
                ? 'Table__selectedRow'
                : ''
            }`}
          >
            <p
              className="truncate"
              style={{ paddingLeft: level * INDENT_PX }}
              onClick={() => handleClick(organization.id)}
            >
              {organization.name}
            </p>
            <div>
              <Delete
                style={{ height: 25, width: 25 }}
                onClick={() => handleDelete(organization)}
              />
            </div>
          </div>
          {organization?.children &&
            organization.children.length > 0 &&
            displayOrganizations(level + 1, organization?.children)}
        </React.Fragment>
      ))}
    </>
  );

  const totalUserOrganizations = userOrganizations?.length;
  return (
    <div className="UserOrganizationListView full-height flex-rows">
      <div className="UserOrganizationListView-header">
        <div className="UserOrganizationListView-header-info">
          {`${totalUserOrganizations ?? 'Loading'} Organizations`}
        </div>
        <IconButton onClick={handleAdd} size="small">
          <Add />
        </IconButton>
      </div>
      <div className="flex-grow">
        <ul className="OrganizationList">
          {displayOrganizations(0, userOrganizations)}
        </ul>
      </div>
      {isUserOrganizationsDialogOpen && (
        <UserOrganizationsDialog
          onClose={closeUserOrganizationsDialog}
          checkedOrganizations={checkedOrganizations}
          setCheckedOrganizations={setCheckedOrganizations}
          userOrganizations={userOrganizations}
          userId={userId}
          setUser={setUser}
        />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
      />
    </div>
  );
};

export default UserOrganizationsListView;
