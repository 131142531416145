import { useOrganizationUsers } from 'api/organizations';
import OrgUsersList from 'components/shared/UsersList/OrgUsersList';
import EditUserDialog from 'components/UsersView/EditUserDialog';
import React, { useState } from 'react';
import AddUserDialog from '../AddUserDialog';
import './OrgUsersView.scss';

interface UsersViewProps {
  organizationId: string;
}

var OrgUsersView = ({ organizationId }: UsersViewProps) => {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [userId, setUserId] = useState<string>('');

  const openAddUserDialog = () => {
    setIsAddUserDialogOpen(true);
  };

  const closeAddUserDialog = () => {
    setIsAddUserDialogOpen(false);
  };

  const editUserDialog = (openDialog: boolean, selectedUserId: string = '') => {
    setUserId(selectedUserId);
    setIsEditUserDialogOpen(openDialog);
  };

  return (
    <>
      <div className="Organization-UsersView">
        <OrgUsersList
          fetchUsers={useOrganizationUsers}
          organizationId={organizationId}
          editUserDialog={editUserDialog}
          openAddUserDialog={openAddUserDialog}
        />
        {isAddUserDialogOpen && (
          <AddUserDialog
            organizationId={organizationId}
            onClose={closeAddUserDialog}
          />
        )}
        {isEditUserDialogOpen && (
          <EditUserDialog
            onClose={editUserDialog}
            userId={userId}
            organizationId={organizationId}
          />
        )}
      </div>
    </>
  );
};

export default OrgUsersView;
