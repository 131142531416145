import { configuredFetch } from 'api/base';
import { Organization } from 'models';

export type MediaRequestParams = {
  orgId: Organization['id'];
};

function mediaUrl({ orgId }: MediaRequestParams) {
  return `/orgs/${orgId}/plugins/velocityvdp/media`;
}

/**
 * Create Media
 * Endpoint: POST /orgs/${orgId}/plugins/velocityvdp/media?pss_name=DEALERDOTCOM_IMAGE_URL
 */
export type CreateMediaRequestParams = MediaRequestParams & {
  image: FormData;
};
async function createMediaBadgeImage({
  orgId,
  image,
}: CreateMediaRequestParams): Promise<string> {
  const url = `${mediaUrl({ orgId })}?pss_name=DEALERDOTCOM_IMAGE_URL`;

  const { data } = await configuredFetch<string>(
    url,
    {
      method: 'post',
      body: image,
    },
    true
  );
  return data;
}

export default {
  createMediaBadgeImage,
};
