/* eslint-disable no-nested-ternary */ // TODO: remove this later
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import './UsersList.scss';

interface UsersHeaderProps {
  totalRecords: number;
  setIsAddUserDialogOpen?: (isAddUserDialogOpen: boolean) => void; // TODO remove conditional
  searchInput: string;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  status: string;
}

const UsersHeader: React.FC<UsersHeaderProps> = ({
  totalRecords,
  setIsAddUserDialogOpen,
  searchInput,
  handleTextChange,
  status,
}) => {
  const headerStatus =
    status === 'loading' ? 'Loading...' : `${totalRecords} Users`;

  const showAddIcon = !!setIsAddUserDialogOpen;

  return (
    <div className="UsersList-header">
      <div id="users-count">{headerStatus}</div>
      <div className="SearchAndActions">
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          className="usersInput"
          value={searchInput}
          onChange={handleTextChange}
          placeholder="Search"
          autoFocus
        />
        {showAddIcon && (
          <Button
            size="small"
            variant="contained"
            aria-label="close"
            color="secondary"
            onClick={() =>
              setIsAddUserDialogOpen && setIsAddUserDialogOpen(true)
            }
            disableRipple
            className="addIconButton"
          >
            <AddIcon style={{ color: 'white' }} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default UsersHeader;
