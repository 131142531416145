import React, { useRef, useState } from 'react';
import { Close, Star } from '@material-ui/icons';
import {
  FolioDocumentTemplate,
  FolioFolder,
  FolioTemplateFolderType,
} from 'models';
import {
  Divider,
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import MediaContainer from './MediaContainer';

import './VelocityEngageMediaTab.scss';

const MAX_FEATURED_DOCUMENTS = 4;

interface VelocityEngageMediaTabProps {
  folioId: string;
  orgId: string;
  folders: FolioFolder[];
}

const VelocityEngageMediaTab: React.FC<VelocityEngageMediaTabProps> = ({
  folioId,
  orgId,
  folders,
}: VelocityEngageMediaTabProps) => {
  const [folderListState, setFolderListState] = useState(folders);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const lastFolderIndex = folders.length - 1;

  const getFeaturedDocumentsLength = () => {
    const featuredDocuments = folders[0];
    return featuredDocuments ? featuredDocuments.documents.length : 0;
  };

  const unFeatureFolioDocument = (folioDocument: FolioDocumentTemplate) => {
    const updatedFolders = folderListState.map((x) => x);
    updatedFolders.forEach((folder) => {
      if (folder.type === FolioTemplateFolderType.FEATURED) {
        const docIndex = folder.documents.findIndex(
          (doc) => doc.id === folioDocument.id
        );
        if (docIndex > -1) {
          folder.documents.splice(docIndex, 1);
        }
      } else {
        const doc = folder.documents.find(
          (item) => item.id === folioDocument.id
        );
        if (doc) {
          doc.featured = false;
        }
      }
    });
    setFolderListState(updatedFolders);
  };

  const featureFolioDocument = (folioDocument: FolioDocumentTemplate) => {
    const updatedFolders = folderListState.map((x) => x);
    let featuredFolder: FolioFolder;
    updatedFolders.forEach((folder) => {
      if (folder.type === FolioTemplateFolderType.FEATURED) {
        featuredFolder = folder;
      } else {
        const doc = folder.documents.find(
          (item) => item.id === folioDocument.id
        );
        if (doc) {
          doc.featured = true;
          featuredFolder.documents.push(doc);
        }
      }
    });
    setFolderListState(updatedFolders);
  };

  const hideFolioDocument = (folioDocument: FolioDocumentTemplate) => {
    const updatedFolders = folderListState.map((x) => x);
    updatedFolders.forEach((folder) => {
      const doc = folder.documents.find((item) => item.id === folioDocument.id);
      if (doc) {
        doc.visible = false;
      }
    });
    setFolderListState(updatedFolders);
  };

  const showFolioDocument = (folioDocument: FolioDocumentTemplate) => {
    const updatedFolders = folderListState.map((x) => x);
    updatedFolders.forEach((folder) => {
      const doc = folder.documents.find((item) => item.id === folioDocument.id);
      if (doc) {
        doc.visible = true;
      }
    });
    setFolderListState(updatedFolders);
  };

  const onFeatureDocumentClick = async (
    folioDocument: FolioDocumentTemplate
  ) => {
    if (
      getFeaturedDocumentsLength() === MAX_FEATURED_DOCUMENTS &&
      !folioDocument.featured
    ) {
      setErrorMessage(strings.TOO_MANY_FEATURED_DOCS);
      return;
    }

    if (folioDocument.featured) {
      unFeatureFolioDocument(folioDocument);
    } else {
      featureFolioDocument(folioDocument);
    }
  };

  const onPublishDocument = async (folioDocument: FolioDocumentTemplate) => {
    if (folioDocument.visible) {
      hideFolioDocument(folioDocument);
      if (folioDocument.featured) {
        unFeatureFolioDocument(folioDocument);
      }
    } else {
      showFolioDocument(folioDocument);
    }
  };

  const onDeleteDocument = (folioDocument: FolioDocumentTemplate) => {
    if (!folioDocument.canDelete) {
      return;
    }
    const updatedFolder = folderListState.map((x) => x);
    updatedFolder.forEach((folder) => {
      const docIndex = folder.documents.findIndex(
        (doc) => doc.id === folioDocument.id
      );
      if (docIndex > -1) {
        folder.documents.splice(docIndex, 1);
        setFolderListState(updatedFolder);
      }
    });
  };

  const onEditDocument = async (
    folioDocument: FolioDocumentTemplate,
    title: string,
    previewImageUrl: string,
    mediaUrl?: string
  ) => {
    const updatedFolders = folderListState.map((x) => x);
    updatedFolders.map((folder) => {
      const item = folder.documents.find(
        (document) => document.id === folioDocument.id
      );
      if (item) {
        item.displayLabel = title;
        item.url = mediaUrl || null;
        item.previewUrl = previewImageUrl || item.type.defaultPreviewImageUrl;
      }
      return item;
    });

    setFolderListState(updatedFolders);
  };

  /**
   * Update document order within a folder after drag-and-drop reordering.
   * @param folderId - The folder for which documents have been reordered.
   * @param reorderObject - A map where keys are document IDs and values are the new index within the folder.
   */
  const reorderDocument = async (
    folderId: string,
    reorderObject: Record<string, number>
  ) => {
    const updatedFolders = folderListState.map((x) => x);
    const folder = updatedFolders.find((item) => item.id === folderId);
    if (!folder) {
      return;
    }
    const updatedDocList: FolioDocumentTemplate[] = [];
    folder.documents.forEach((doc) => {
      const index: number = reorderObject[doc.id];
      if (index > -1) {
        updatedDocList[index] = doc;
      }
    });
    folder.documents = updatedDocList;
    setFolderListState(updatedFolders);
  };

  const handleClose = () => setErrorMessage('');

  return (
    <div className="VelocityEngageMediaTab" ref={scrollRef}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        folderListState.map((folder: FolioFolder, idx: number) => (
          <React.Fragment key={folder.id}>
            <MediaContainer
              orgId={orgId}
              folderId={folder.id}
              folioId={folioId}
              icon={
                folder.displayLabel === strings.FEATURED ? (
                  <Star className="Featured-icon" />
                ) : null
              }
              title={folder.displayLabel}
              media={folder.documents}
              scrollRef={scrollRef}
              onStar={onFeatureDocumentClick}
              onPublish={onPublishDocument}
              onDelete={onDeleteDocument}
              onEdit={onEditDocument}
              onReorder={reorderDocument}
            />
            {idx !== lastFolderIndex && <Divider />}
          </React.Fragment>
        ))
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!errorMessage}
        onClose={handleClose}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={<span>{errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
};

export default VelocityEngageMediaTab;
