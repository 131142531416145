import React from 'react';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import KeyValueFieldTable from 'components/shared/KeyValueFieldTable';
import { TextField } from '@material-ui/core';

export interface MatchCriteriaProps {
  key: string;
  value: string;
}

interface MatchCriteriaListProps {
  dealerId: string;
  setDealerId: (dealerId: string) => void;
  matchCriteria: MatchCriteriaProps[];
  setMatchCriteria: (mc: MatchCriteriaProps[]) => void;
}

const MatchCriteriaList: React.FC<MatchCriteriaListProps> = ({
  dealerId,
  setDealerId,
  matchCriteria,
  setMatchCriteria,
}: MatchCriteriaListProps) => {
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    onAccept,
    onDeny,
    openConfirmationDialog,
    primaryButtonLabel,
  } = useConfirmationDialog();

  const onDeleteRow = (rowIndex: number, key: string) => {
    openConfirmationDialog({
      messageOverride: `Remove column "${key}"?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: () => {
        setMatchCriteria(
          matchCriteria.filter((criteria) => criteria.key !== key)
        );
      },
    });
  };

  return (
    <>
      <TextField
        autoFocus
        className="PluginDialog__text-input"
        label="DMS ID"
        variant="outlined"
        required
        value={dealerId}
        onChange={(e) => {
          setDealerId(e.target.value);
        }}
        margin="dense"
      />

      <KeyValueFieldTable
        label="Match Criteria"
        matchCriteria={matchCriteria}
        onDeleteRow={onDeleteRow}
        onChange={(index, property, newValue) => {
          const currentKeyValuePair = matchCriteria[index];
          const newKeyValuePair = { ...currentKeyValuePair };
          newKeyValuePair[property] = newValue;
          const newMatchCriteria = [...matchCriteria];
          newMatchCriteria.splice(index, 1, newKeyValuePair);
          setMatchCriteria(newMatchCriteria);
        }}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        isLoading={isConfirmationLoading}
        message={confirmationMessage}
      />
    </>
  );
};

export default MatchCriteriaList;
