import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List } from 'react-window';

import { User } from 'models';
import {
  Column,
  ListItemProps,
  ListRendererProps,
  OrgGroupUsersListProps,
  TableColumnsProps,
} from './types';

import './UsersList.scss';
import UsersHeader from './UsersHeader';
import UsersListRow from './UsersListRow';
import UsersListLoading from './UsersListLoading';

const useScrollBarDetector = (heightOfContent: number): boolean => {
  const [isScrollbar, setIsScrollbar] = useState(false);

  useEffect(() => {
    const { innerHeight: windowHeight } = window;
    if (heightOfContent > windowHeight) {
      setIsScrollbar(true);
    } else {
      setIsScrollbar(false);
    }
  }, [heightOfContent]);

  return isScrollbar;
};

type UseDebouncedInputReturnType = [
  searchInput: string,
  setSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
];

const useDebouncedInput = (
  setInputAfterDelay: (newInput: string) => void
): UseDebouncedInputReturnType => {
  const [searchInput, setSearchInput] = useState<string>('');
  const sendTextChange = debounce(
    (newValue) => setInputAfterDelay(newValue),
    250,
    { leading: false, trailing: true }
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = React.useCallback(debounce(sendTextChange, 10), []);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    handler(e.target.value);
  };

  return [searchInput, handleTextChange];
};

const defaultHeaderColumns: Column[] = [
  {
    dataKey: 'fullName',
    label: 'Name',
    width: '200px',
  },
  {
    dataKey: 'jobTitle',
    label: 'Title',
    width: '100px',
  },
  {
    dataKey: 'status',
    label: 'Status',
    width: '100px',
  },
  {
    dataKey: 'phone',
    label: 'Phone',
    width: '100px',
  },
  {
    dataKey: 'email',
    label: 'Email',
    width: '100px',
  },
  {
    dataKey: 'actionButtons',
    label: '',
    width: '75px',
  },
];

const ROW_HEIGHT = 30;

const OrgGroupUsersList: React.FC<OrgGroupUsersListProps> = ({
  fetchUsers,
  editUserDialog,
  organizationId,
  groupId,
  openAddUserDialog,
  headerColumns = defaultHeaderColumns,
  onDeleteUser,
}) => {
  const [searchText, setSearchText] = useState<string>();
  const [searchInput, handleTextChange] = useDebouncedInput(setSearchText);

  const { data, status, fetchNextPage } = fetchUsers(
    organizationId,
    groupId,
    searchText
  ); // TODO refactor over engineered userslist component

  const users =
    data?.pages?.reduce(
      (acc: User[], pageUsers) => [...acc, ...pageUsers.data],
      []
    ) ?? []; // TODO use Memo or handle flat users differently
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isItemLoaded = (index: number) => !!users && users.length >= index;
  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      if (users.length < stopIndex) {
        return fetchNextPage();
      }
      return Promise.resolve();
    },
    [fetchNextPage, users.length]
  );

  const totalRecords =
    data?.pages && data.pages?.length > 0 ? data.pages[0].meta.totalRecords : 0;
  const isScrollbarPresent = useScrollBarDetector(totalRecords * ROW_HEIGHT);
  var TableColumns = ({ columns }: TableColumnsProps) => {
    return (
      <TableRow
        component="div"
        className={`row header ${
          isScrollbarPresent ? 'scrollbarAdjustment' : ''
        }`}
      >
        {columns.map((column: Column) => {
          const isActionButtonsCell = column.dataKey === 'actionButtons';
          return (
            <TableCell
              key={`${column.dataKey}columnHeader`}
              component="div"
              variant="head"
              align={column.dataKey === 'actionButtons' ? 'right' : 'left'}
              className={`cell header-cell ${
                isActionButtonsCell ? 'actionButtons' : ''
              }`}
              style={{
                minWidth: column.width,
                height: ROW_HEIGHT,
              }}
              scope="col"
            >
              {column.label}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  // const headerStatus = state.filteredCollection.data.totalLength === -1 TODO
  //   ? `Loading...`
  //   : `${currentCountOfUsers} Users`;
  if (status === 'loading') {
    return <UsersListLoading />;
  }

  if (data?.pages?.length === 0) {
    return <div>No Users</div>;
  }

  return (
    <div className="UsersList">
      <div className="flex-grow">
        <UsersHeader
          totalRecords={totalRecords}
          searchInput={searchInput}
          handleTextChange={handleTextChange}
          status={status}
          setIsAddUserDialogOpen={openAddUserDialog}
        />
        <div className="flex-columns UsersList-table">
          <Table className="table" component="div">
            <TableHead component="div">
              <TableColumns columns={headerColumns} />
            </TableHead>
            <TableBody component="div" className="full-height">
              <AutoSizer>
                {({ height, width }: ListRendererProps) => (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={totalRecords}
                    loadMoreItems={loadMoreItems}
                  >
                    {({ onItemsRendered, ref }) => (
                      <List
                        className="list"
                        height={height}
                        width={width}
                        itemCount={totalRecords}
                        itemSize={ROW_HEIGHT}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                      >
                        {({ index, style }: ListItemProps) => {
                          if (!users) {
                            return <Skeleton />;
                          }

                          const user: User = users[index];
                          return (
                            <UsersListRow
                              key={`${user && user.id ? user.id : 0}${index}`}
                              style={style}
                              index={index}
                              user={user}
                              editUserDialog={editUserDialog}
                              headerColumns={defaultHeaderColumns}
                              onDeleteUser={onDeleteUser}
                            />
                          );
                        }}
                      </List>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default OrgGroupUsersList;
