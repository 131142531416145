import { defaultMutationFn } from 'api';
import { configuredFetch } from 'api/base';
import { Organization } from 'models';
import {
  DataProviderPluginItem,
  PluginItem,
  PluginOption,
} from 'models/plugins';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useInvalidateOrganizationGroups } from '../groups';
import reconvelocity from './reconvelocity';
import velocityengage from './velocityengage';

// Todo: refactor components using redux + plain fetch to use react query hooks.
// Until then leave the param types and baseUrl function here.
export type PluginsRequestParams = {
  orgId: Organization['id'];
};

export type PluginRequestParams = PluginsRequestParams & {
  pluginId: string;
};

export type AssignPluginRequestParams = PluginRequestParams & {
  data?: object;
};

function baseUrl({ orgId }: PluginsRequestParams) {
  return `/orgs/${orgId}/plugins`;
}

function pluginListUrl(orgId: string) {
  return `/orgs/${orgId}/plugins`;
}

function pluginUrl(orgId: string, pluginTypeId: string) {
  return `/orgs/${orgId}/plugins/${pluginTypeId}`;
}

/**
 * Get Organization Plugins Options
 * Endpoint: GET /orgs/{orgId}/plugins/options
 */
async function getOptions({ orgId }: PluginsRequestParams) {
  const url = `${baseUrl({ orgId })}/options`;
  const { data } = await configuredFetch<PluginOption[]>(url);
  return data;
}

/**
 * Get Organization Plugins
 * Endpoint: GET /orgs/{orgId}/plugins
 */
async function getPlugins({ orgId }: PluginsRequestParams) {
  const url = `${baseUrl({ orgId })}`;
  const { data } = await configuredFetch<PluginItem[]>(url);
  return data;
}

/**
 * @deprecated Prefer the react-query function below using the same parameters.
 * Get Organization Plugin
 * Endpoint: GET /orgs/{orgId}/plugins/{pluginId}
 */
async function getPlugin({ orgId, pluginId }: PluginRequestParams) {
  const url = `${baseUrl({ orgId })}/${pluginId}`;
  const { data } = await configuredFetch<PluginItem>(url);
  return data;
}

/**
 * Get organization plugins (react-query)
 * Endpoint: GET /orgs/{orgId}/plugins
 */
export function usePlugins(orgId: string) {
  const path = pluginListUrl(orgId);
  return useQuery<any>(path, { enabled: !!orgId, placeholderData: [] });
}

/**
 * Get organization plugin (react-query)
 * Endpoint: GET /orgs/{orgId}/plugins/{pluginId}
 */
export function usePlugin(
  orgId: string,
  pluginTypeId: string,
  pluginId: string | undefined
) {
  const path = pluginUrl(orgId, pluginTypeId);
  return useQuery<any>(path, {
    enabled: !!pluginId,
  });
}

/**
 * Update orginazation plugin (react-query)
 * Endpoint: PUT /orgs/{orgId}/plugins/{pluginId}
 */
export function usePluginUpdate(orgId: string, pluginId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: Partial<DataProviderPluginItem>) => {
      const path = pluginUrl(orgId, pluginId);
      return defaultMutationFn(path, 'PUT', data);
    },
    {
      onSuccess: (data, variables, context) => {
        const individualPluginPath = pluginUrl(orgId, pluginId);
        const pluginListPath = pluginListUrl(orgId);
        queryClient.invalidateQueries(individualPluginPath);
        queryClient.invalidateQueries(pluginListPath);
      },
    }
  );

  return {
    ...mutation,
    updatePluginAsync: mutation.mutateAsync,
  };
}

/**
 * Create orginazation plugin (react-query)
 * Endpoint: POST /orgs/{orgId}/plugins/{pluginId}
 */
export function usePluginCreate(orgId: string, pluginId: string) {
  const queryClient = useQueryClient();
  const invalidateOrganizationGroups = useInvalidateOrganizationGroups();
  const mutation = useMutation(
    (data: Partial<DataProviderPluginItem>) => {
      const path = pluginUrl(orgId, pluginId);
      return defaultMutationFn(path, 'POST', data);
    },
    {
      onSuccess: (data, variables, context) => {
        const individualPluginPath = pluginUrl(orgId, pluginId);
        const pluginListPath = pluginListUrl(orgId);
        queryClient.invalidateQueries(individualPluginPath);
        queryClient.invalidateQueries(pluginListPath);
        invalidateOrganizationGroups(orgId);
      },
    }
  );

  return {
    ...mutation,
    createPluginAsync: mutation.mutateAsync,
  };
}

/**
 * DELETE orginazation plugin (react-query)
 * Endpoint: DELETE /orgs/{orgId}/plugins/{pluginId}
 */
export function usePluginDelete() {
  const queryClient = useQueryClient();
  const invalidateOrganizationGroups = useInvalidateOrganizationGroups();
  const mutation = useMutation(
    ({ orgId, pluginId }: { orgId: string; pluginId: string }) => {
      const path = pluginUrl(orgId, pluginId);
      return defaultMutationFn(path, 'DELETE');
    },
    {
      retry: 1,
      onSuccess: async (data, variables, context) => {
        const individualPluginPath = pluginUrl(
          variables.orgId,
          variables.pluginId
        );
        const pluginListPath = pluginListUrl(variables.orgId);

        const allPlugins: PluginItem[] | undefined =
          await queryClient.getQueryData(pluginListUrl(variables.orgId));
        if (allPlugins) {
          const currentPlugin = allPlugins.find(
            (listPlugin) => listPlugin.id === variables.pluginId
          );
          const pluginTypeId = currentPlugin?.pluginName
            .toLowerCase()
            .replace('_', '');
          if (pluginTypeId) {
            const pluginPath = pluginUrl(variables.orgId, pluginTypeId);
            await queryClient.resetQueries(pluginPath);
          }
        }

        await invalidateOrganizationGroups(variables.orgId);
        await queryClient.invalidateQueries(individualPluginPath);
        await queryClient.invalidateQueries(pluginListPath);
      },
    }
  );

  return {
    ...mutation,
  };
}

/**
 * @deprecated Prefer the react-query function above using the same parameters.
 * Assign Organization Plugin
 * Endpoint: POST /orgs/{orgId}/plugins/{pluginId}
 */
async function assignPlugin({
  orgId,
  pluginId,
  data,
}: AssignPluginRequestParams) {
  const url = `${baseUrl({ orgId })}/${pluginId}`;
  const { data: responseData } = await configuredFetch<PluginItem>(url, {
    method: 'post',
    body: JSON.stringify(data),
  });
  return responseData;
}

/**
 * Unassign Organization Plugin
 * Endpoint: DELETE /orgs/{orgId}/plugins/{pluginId}
 */
async function unassignPlugin({ orgId, pluginId }: PluginRequestParams) {
  const url = `${baseUrl({ orgId })}/${pluginId}`;
  const { data } = await configuredFetch<boolean>(url, {
    method: 'delete',
  });
  return data;
}

/**
 * Get organization plugins (react-query)
 * Endpoint: GET /orgs/{orgId}/plugins
 */
export function usePluginOptions(orgId: string) {
  const path = `/orgs/${orgId}/plugins/options`;
  return useQuery<any>(path, { enabled: !!orgId, placeholderData: [] });
}

export default {
  getOptions,
  getPlugins,
  getPlugin,
  assignPlugin,
  unassignPlugin,
  usePlugins,
  usePlugin,
  usePluginUpdate,
  usePluginCreate,
  usePluginDelete,
  velocityengage,
  reconvelocity,
  usePluginOptions,
};
