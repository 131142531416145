import React, { useState, useEffect } from 'react';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';

import {
  Organization,
  PluginItem,
  PluginItemCategory,
  PluginOption,
} from 'models';
import useSession from 'api/session';
import { usePluginOptions, usePlugins } from 'api/organizations/plugins';
import permissions from 'common/permissions';
import utils from 'utils';
import DmsPluginDialog from './DmsPluginDialog';
import EnhancerPluginDialog from './EnhancerPluginDialog';
import ImsPluginDialog from './ImsPluginDialog';
import ReconVelocityPluginDialog from './ReconVelocityPluginDialog';
import VelocityInsightPluginDialog from './VelocityInsightPluginDialog';

import VelocityEngagePluginDialog from './VelocityEngagePluginDialog/VelocityEngagePluginDialog';
import PermissionError from '../PermissionErrorView';
import PluginSection from './PluginSection';

import './PluginsView.scss';
import DmsMatchCriteriaPerBusinessLinePluginDialog from './DmsMatchCriteriaPerBusinessLinePluginDialog';
import { useInvalidateOrganizationGroups } from 'api/organizations/groups';

interface Section {
  category: PluginItemCategory;
  heading: string;
}

const SECTIONS: Section[] = [
  { category: PluginItemCategory.DATA_PROVIDER, heading: 'Data Providers' },
  { category: PluginItemCategory.ENHANCER, heading: 'Enhancers' },
  { category: PluginItemCategory.MODULE, heading: 'Modules' },
];

function getEnhancerPluginDialogComponent({
  plugin,
  orgId,
  onSave,
  onClose,
}: {
  plugin: PluginItem;
  orgId: Organization['id'];
  onSave?: () => void;
  onClose?: () => void;
}) {
  return (
    <EnhancerPluginDialog
      plugin={plugin}
      orgId={orgId as string}
      onSave={onSave}
      onClose={onClose}
    />
  );
}

function getPluginDialogComponent({
  plugin,
  orgId,
  onSave,
  onClose,
  organization,
  isNewPlugin,
}: {
  plugin: PluginItem;
  orgId: Organization['id'];
  onSave?: () => void;
  onClose?: () => void;
  organization: Organization;
  isNewPlugin: boolean;
}) {
  let Dialog: React.FC<any> | undefined;

  if (plugin.category === 'ENHANCER') {
    return getEnhancerPluginDialogComponent({ plugin, orgId, onSave, onClose });
  }

  switch (plugin.category) {
    case 'DATA_PROVIDER':
      if (plugin.subCategory === 'DMS') {
        Dialog =
          plugin.pluginName === 'CDK'
            ? DmsMatchCriteriaPerBusinessLinePluginDialog
            : DmsPluginDialog;
      } else if (plugin.subCategory === 'IMS') {
        Dialog = ImsPluginDialog;
      }
      break;

    case 'MODULE':
    default:
      if (plugin.pluginName === 'RECON_VELOCITY') {
        Dialog = ReconVelocityPluginDialog;
      } else if (plugin.pluginName === 'VELOCITY_INSIGHT') {
        Dialog = VelocityInsightPluginDialog;
      } else if (
        ['VELOCITY_VDP', 'VELOCITY_ENGAGE'].includes(plugin.pluginName)
      ) {
        Dialog = VelocityEngagePluginDialog;
      }
      break;
  }

  if (!Dialog) return null;

  return (
    <Dialog
      plugin={plugin}
      orgId={orgId}
      organization={organization}
      onSave={onSave}
      onClose={onClose}
      isNewPlugin={isNewPlugin}
    />
  );
}

interface PluginDialogProps {
  plugin: PluginItem;
  orgId: Organization['id'];
  onSave?: () => void;
  onClose?: () => void;
  organization: Organization;
  isNewPlugin: boolean;
}

function PluginDialog({
  plugin,
  orgId,
  onSave,
  onClose,
  organization,
  isNewPlugin,
}: PluginDialogProps) {
  return getPluginDialogComponent({
    plugin,
    orgId,
    onSave,
    onClose,
    organization,
    isNewPlugin,
  });
}

interface PluginsViewProps {
  organization: Organization;
}

var PluginsView = ({ organization }: PluginsViewProps) => {
  // Queries
  const { data: sessionData, isLoading: sessionIsLoading } = useSession();
  const {
    data: queryPlugins,
    isLoading: pluginsAreLoading,
    error: pluginsError,
    refetch: reloadPlugins,
  } = usePlugins(organization.id);
  const {
    data: queryPluginOptions,
    isLoading: pluginOptionsAreLoading,
    error: pluginOptionsError,
    refetch: reloadPluginOptions,
  } = usePluginOptions(organization.id);

  // State
  const [pluginBeingEdited, setPluginBeingEdited] = useState<
    PluginItem | undefined
  >();
  const [isNewPluginBeingAdded, setIsNewPluginBeingAdded] = useState(false);
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  useEffect(() => {
    if (pluginsError || pluginOptionsError) {
      openAlert('There was a network error loading the plugins', 'error');
    }
  }, [pluginOptionsError, pluginsError, openAlert]);

  const hasViewPluginsPermission = () =>
    utils.permissions.hasPermission(
      permissions.ORGS_PLUGINS_VIEW,
      sessionData?.permissions
    );

  const isLoading =
    pluginsAreLoading || sessionIsLoading || pluginOptionsAreLoading;

  const handleEditPluginClick = (plugin: PluginItem) => {
    const DialogComponent = getPluginDialogComponent({
      plugin,
      orgId: organization.id,
      organization,
      isNewPlugin: isNewPluginBeingAdded,
    });
    if (DialogComponent) {
      setPluginBeingEdited(plugin);
      setIsNewPluginBeingAdded(false);
    } else {
      openAlert('This plugin type cannot be edited yet.', 'info');
    }
  };
  const invalidateOrganizationGroups = useInvalidateOrganizationGroups();

  // TODO - remove adding plugin until clicking save in modal because each plugin has different defaults.
  const handleAddPluginOption = (
    pluginOption: PluginOption,
    settings: Record<string, any> = {}
  ) => {
    const newPlugin = {
      title: pluginOption.title,
      pluginName: pluginOption.pluginName,
      category: pluginOption.category,
      subCategory: pluginOption.subCategory,
      iconURL: pluginOption.iconURL,
      links: pluginOption.links,
      enabled: true,
      settings,
      iconUrl: '',
      description: pluginOption.description ? pluginOption.description : '',
    };
    const DialogComponent = getPluginDialogComponent({
      plugin: newPlugin,
      orgId: organization.id,
      organization,
      isNewPlugin: isNewPluginBeingAdded,
    });
    if (DialogComponent) {
      setPluginBeingEdited(newPlugin);
      setIsNewPluginBeingAdded(true);
      invalidateOrganizationGroups(organization.id);
    } else {
      openAlert('This plugin type cannot be added yet.', 'info');
    }
  };

  if (!isLoading && !hasViewPluginsPermission()) {
    <PermissionError feature="Plugins" organizationName={organization.name} />;
  }

  return (
    <>
      {!isLoading ? (
        <div className="PluginsView tab-content padding">
          {SECTIONS.map((section) => (
            <PluginSection
              key={section.category}
              category={section.category}
              heading={section.heading}
              onAddPluginOption={handleAddPluginOption}
              onEditPlugin={handleEditPluginClick}
              plugins={queryPlugins}
              pluginOptions={queryPluginOptions}
              orgId={organization.id}
              onDeletePlugin={() => {
                reloadPluginOptions();
                reloadPlugins();
                setIsNewPluginBeingAdded(false);
                setPluginBeingEdited(undefined);
              }}
            />
          ))}
        </div>
      ) : (
        <LoadingIndicator />
      )}
      {pluginBeingEdited && (
        <PluginDialog
          orgId={organization.id}
          key={pluginBeingEdited.id}
          plugin={pluginBeingEdited}
          onSave={() => reloadPlugins()}
          onClose={() => {
            setPluginBeingEdited(undefined);
            setIsNewPluginBeingAdded(false);
            reloadPlugins();
            reloadPluginOptions();
          }}
          organization={organization}
          isNewPlugin={isNewPluginBeingAdded}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        duration={3500}
        variant={variant}
        onClose={closeAlert}
      />
    </>
  );
};

export default PluginsView;
